<template>
    <div class="academy">
        <Anchor :id="'education'" />
        <div class="block">
            <div class="block-title">
                <div class="dot" />
                {{ $t('academy.landing.title[0]') }}
            </div>
            <div class="block-content">
                {{ $t('academy.landing.content[0]') }}
            </div>
        </div>
        <Anchor :id="'curriculum'" />
        <div class="block">
            <div class="block-title">
                <div class="dot" />
                {{ $t('academy.landing.title[1]') }}
            </div>
            <div class="block-content">
                {{ $t('academy.landing.content[1]') }}
            </div>
        </div>
        <Anchor :id="'curriculum-now'" />
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('academy.landing.subtitle[0]') }}
            </div>
            <ThreeBlock
                :block-data="curriculumNow"
                :show-data="showData"
            />
        </div>
        <Anchor :id="'curriculum-history'" />
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('academy.landing.subtitle[1]') }}
            </div>
            <ThreeBlock
                :block-data="curriculumHistory"
                :show-data="showData"
            />
        </div>
        <Anchor :id="'block'" />
        <div class="block">
            <div class="block-title">
                <div class="dot" />
                {{ $t('academy.landing.title[2]') }}
            </div>
            <div class="block-content">
                {{ $t('academy.landing.content[2]') }}
            </div>
        </div>
        <Anchor :id="'reading-now'" />
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('academy.landing.subtitle[0]') }}
            </div>
            <ThreeBlock
                :block-data="readingNow"
                :show-data="showData"
            />
        </div>
        <Anchor :id="'reading-history'" />
        <div class="sub-block">
            <div class="sub-title">
                {{ $t('academy.landing.subtitle[1]') }}
            </div>
            <ThreeBlock
                :block-data="readingHistory"
                :show-data="showData"
            />
        </div>
        <Anchor :id="'history'" />
        <div class="block mb-16">
            <div class="block-title">
                <div class="dot" />
                {{ $t('academy.landing.title[3]') }}
            </div>
            <ThreeBlock
                :block-data="History"
                :router-name="'AcademyHistoryItem'"
                :more-router-link="'AcademyHistory'"
                :show-data="showData"
            />
        </div>
    </div>
</template>

<script>
import API from '@/api';
import { mapMutations } from 'vuex';
import ThreeBlock from '@/components/list/ThreeBlock.vue';

export default {
    components: {
        ThreeBlock,
    },
    data() {
        return {
            showData: false,
            curriculumNow: {
                type: 'link',
                items: null,
            },
            curriculumHistory: {
                type: 'link',
                items: null,
            },
            readingNow: {
                type: 'link',
                items: null,
            },
            readingHistory: {
                type: 'link',
                items: null,
            },
            History: {
                type: 'router',
                items: null,
            },
        };
    },
    watch: {
        $route() {
            this.init();
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapMutations('mNotification', ['setAlert', 'handleError']),
        async init() {
            try {
                const res = await API.EducationThink.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                this.curriculumNow.items = res.data.raw.filter(
                    (value) => value.type === 'onGoing',
                );
                this.curriculumHistory.items = res.data.raw.filter(
                    (value) => value.type === 'past',
                );
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
            try {
                const res = await API.EducationWalk.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                this.readingNow.items = res.data.raw.filter(
                    (value) => value.type === 'onGoing',
                );
                this.readingHistory.items = res.data.raw.filter(
                    (value) => value.type === 'past',
                );
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
            try {
                const res = await API.EducationHistory.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                this.History.items = res.data.raw;
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
            this.showData = true;
        },
    },
};
</script>

<style lang="scss">
.academy{
    color: $color-gray;

    .block-content{
      font-size: 16px;
      font-weight: 700;
      line-height: 2;
      text-align: justify;
      letter-spacing: 0.1em;
    }
}
</style>
